.page__partners{
    max-width: 1440px;
    width: 100%;
}
.partners__container{
    padding-left: 140px;
    padding-top: 109px;
    padding-right: 140px;
}
.partners__grid {
    padding-top: 75px;
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 25px 25px;
    grid-template-columns: repeat(auto-fit, minmax(265px, 0fr));
  
  }
  .partners__title {
    font-size: 48px;
    font-weight: 500;
    margin: 0;
}

  .partners__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s, filter 0.3s; /* Добавляем плавную анимацию для увеличения и изменения цвета */
  }
  
  .partners__img:hover {
    transform: scale(1.2); /* Увеличиваем размер изображения */
    filter: brightness(110%); /* Подсвечиваем изображение */
  }

  @media screen and (max-width: 768px) {
      .partners__grid {
          gap: 12px;
          grid-template-columns: repeat(auto-fit, minmax(150px, 0fr));
        }
        .partners__container{
          padding-left: 30px;
          padding-top: 69px;
          padding-right: 30px;
      }
  }
  @media (max-width: 459px) {
    .partners__title {
      font-size: 24px;

  }
  .partners__grid {
    padding-top: 30px;

  }
  .partners__container{
    padding-left: 20px;
    padding-top: 59px;
    padding-right: 20px;
}
  }
