@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);
.page {
  margin: 0 auto;
  font-family: 'SF Pro Display', Arial, sans-serif;                                                                                              
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
   background-color: var(--grey-color);
   -webkit-text-size-adjust: 100%;
   -ms-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
   text-rendering: optimizeLegibility;

}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
   monospace;
}

