.page__content {
 max-width: 1440px;
 width: 100%;
 margin: 0 auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color: white;
}
:root{
  --grey-color: #F3F3F3;


}
