@font-face {
    font-family: 'SF Pro Display';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url(".//sf-pro-display_regular.woff2") format("woff2");
   
  }
  @font-face {
    font-family: 'SF Pro Display';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("./sf-pro-display_medium.woff2") format("woff2");
        
  }
  @font-face {
    font-family: 'SF Pro Display';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("./sf-pro-display_bold.woff2") format("woff2");
         
  }
