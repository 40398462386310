.card {
    max-width: 525px;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8); ;
}
.card__container {
    display: flex;


}
.card__block {
    display: flex;
    flex-direction: column;
}
.card__img {
    max-width: 200px;
    width: 100%;
    padding-right: 15px;
}
.card__block,
.card__header {
    display: flex;
    flex-direction: column;
}
.card__header {
    margin-bottom: 10px;
}
.card__subtitle{
    margin: 5px 0 5px 0;
}
.card__title {
    margin-bottom: 5px;
}
.card__links {
    color: #EF7E18;
    font-size: 18px;
    transition: transform 0.3s, filter 0.3s;
    margin: 5px 0 5px 0;
    
 }
 .card__links:hover {
     color: #EF7E18;
     font-size: 25px;
     transform: scale(1.2); /* Увеличиваем размер изображения */
     filter: brightness(110%); /* Подсвечиваем изображение */
  }

@media (max-width: 550px) { 
    .card {
        max-width: 320px;
        padding-bottom: 20px;
    }
    .card__container {
        flex-direction: column;
        align-items: center;
    }
    .card__block {
        justify-content: flex-start;
        align-items: flex-start;
    }
    

}