.page__contacts {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  
}
.contacts__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    gap: 40px;
}
.contacts__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.contacts__links {
   color: #EF7E18;
   font-size: 25px;
   transition: transform 0.3s, filter 0.3s;
   
}
.contacts__links:hover {
    color: #EF7E18;
    font-size: 25px;
    transform: scale(1.2); /* Увеличиваем размер изображения */
    filter: brightness(110%); /* Подсвечиваем изображение */
 }
 @media (max-width: 1140px) {
    .contacts__container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        justify-content: space-around;
        align-items: center;
        max-width: 1200px;
        width: 100%;
        
    }
 }