.page__about {
    max-width: 1440px;
    width: 100%;
}
.about__container {
    padding-left: 140px;
    padding-top: 109px;
    
}
.about__title {
    font-size: 48px;
    font-weight: 500;
    margin: 0;
}
.about__text {
    margin: 0;
    max-width: 1000px;
    width: 100%;
    font-size: 24px;
    line-height: 35px;
    box-sizing: border-box;
    padding-top: 75px;
}
@media (max-width: 768px) {
    .about__container {
        padding-left: 30px;
        padding-top: 59px;
        
    }
}
@media (max-width: 459px) {
    .about__container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        
    }
    .about__title {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
    }
    .about__text {
        font-size: 15px;
        padding-top: 24px;
    }
}