.page__promo {
    width: 100%;
    max-width: 1440px;
  
}
.promo__link {
  text-decoration: none;
}
.promo__container {
    position: relative;
    background-image: url("../../images/kls.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
   
  }
  
  .promo__container::before {
    content: "";
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .promo__content {
    position: relative;
    padding-left: 140px;
    padding-top: 20px;
    padding-bottom: 254px;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  .promo__logo{
		box-sizing: border-box;
    z-index: 6; 
    width: 400px;
    background-position: center;
    height: 300px;
    background-image: url("../../images/logoBlack.png");
    transition: opacity .3s linear;
    background-size: contain;
    background-color: #ffffff25;
		box-shadow: 10px 10px 10px 30px #ffffff25;
		margin-top: 15px;
    border-radius: 10px;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-self: center;
  
  } 
.promo__text{
    font-size: 60px;
    font-weight: 700;
    line-height: 55px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
    color: white;
    max-width: 971px;
    width: 100%;
   margin: 0;
}

.promo__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    width: 100%;
    height: 75px;
    border-radius: 50px;
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;
    background-color: #EF7E18;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.8);
    color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
    margin-top: 84px;
    transition: transform 0.3s, filter 0.3s;
    align-self: flex-start; 
}
.promo__btn:hover {
  transform: scale(1.2); /* Увеличиваем размер изображения */
  filter: brightness(110%); /* Подсвечиваем изображение */
  
}
.promo__arrow{
    background-image: url('../../images/arrow.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
    
}
@media (max-width: 768px) {
  .promo__content {
    padding-left: 30px;
    padding-top: 83px;
    padding-bottom: 154px;
  }
  .promo__text{
    font-size: 40px;
  }
  .promo__btn {
    font-size: 20px;
  }
}

@media (max-width: 459px) {
  .promo__logo{
    width: 250px;
    height: 100px;
  }
  .promo__container {
    justify-content: center;
    align-items: center;
  }
  .promo__content {

    padding: 20px 20px 54px 20px;

   
  }
  .promo__text{
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
    color: white;
    max-width: 320px;
    width: 100%;
    text-align: center;
  }
  .promo__btn {
    font-size: 15px;
   width: 324px;
   height: 40px;
   margin-top: 20px;

    
  }
  }